import { template as template_d07ccc4816c2430e8994c99afd7a05a0 } from "@ember/template-compiler";
import PixBannerAlert from '@1024pix/pix-ui/components/pix-banner-alert';
import { t } from 'ember-intl';
export default template_d07ccc4816c2430e8994c99afd7a05a0(`
  <PixBannerAlert @type="info">
    {{t "pages.modulix.beta-banner"}}
  </PixBannerAlert>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
