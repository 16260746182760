import { template as template_5c388078af654365b03c34cb5f5aa4fa } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
export default class Header extends Component {
    @service
    url;
    get showcase() {
        return this.url.showcase;
    }
    static{
        template_5c388078af654365b03c34cb5f5aa4fa(`
    <header class="authentication-layout-header" role="banner">
      <a href={{this.showcase.url}} class="pix-logo__link">
        <img class="pix-logo__image" src="/images/pix-logo.svg" alt="{{this.showcase.linkText}}" />
      </a>
      {{yield}}
    </header>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
