import { template as template_5ef203a7fcda48c8bd4d5419f18fc172 } from "@ember/template-compiler";
export default template_5ef203a7fcda48c8bd4d5419f18fc172(`
  <ul class="responsive-list-wide-wrap">
    {{yield}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
