import { template as template_6f9bf7ffbd1747459a1d7800e7f27238 } from "@ember/template-compiler";
import PixIcon from '@1024pix/pix-ui/components/pix-icon';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import RewardsBadge from './badge';
export default class Rewards extends Component {
    @service
    metrics;
    constructor(){
        super(...arguments);
        this.metrics.add({
            event: 'custom-event',
            'pix-event-category': 'Fin de parcours',
            'pix-event-action': 'Affichage onglet',
            'pix-event-name': "Affichage de l'onglet Récompenses"
        });
    }
    getFilteredAndSortedBadges(acquisitionStatus) {
        return this.args.badges.slice().filter(({ isAcquired })=>isAcquired === acquisitionStatus).sort((a, b)=>b.isCertifiable - a.isCertifiable);
    }
    get acquiredBadges() {
        return this.getFilteredAndSortedBadges(true);
    }
    get notAcquiredBadges() {
        return this.getFilteredAndSortedBadges(false).filter(({ isAlwaysVisible })=>isAlwaysVisible);
    }
    static{
        template_6f9bf7ffbd1747459a1d7800e7f27238(`
    <h2 class="evaluation-results-tab__title">
      {{t "pages.skill-review.tabs.rewards.title"}}
    </h2>
    <p class="evaluation-results-tab__description">
      {{t "pages.skill-review.tabs.rewards.description"}}
    </p>
    {{#if this.acquiredBadges.length}}
      <h2 class="evaluation-results-tab__badges-title evaluation-results-tab__badges-title--acquired">
        <PixIcon @name="checkCircle" @plainIcon={{true}} @ariaHidden={{true}} />
        {{t "pages.skill-review.badge-card.acquired"}}
      </h2>
      <ul class="evaluation-results-tab__badges-list">
        {{#each this.acquiredBadges as |badge|}}
          <RewardsBadge @badge={{badge}} />
        {{/each}}
      </ul>
    {{/if}}
    {{#if this.notAcquiredBadges.length}}
      <h2 class="evaluation-results-tab__badges-title evaluation-results-tab__badges-title--not-acquired">
        <PixIcon @name="cancel" @plainIcon={{true}} @ariaHidden={{true}} />
        {{t "pages.skill-review.badge-card.not-acquired"}}
      </h2>
      <ul class="evaluation-results-tab__badges-list">
        {{#each this.notAcquiredBadges as |badge|}}
          <RewardsBadge @badge={{badge}} />
        {{/each}}
      </ul>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
