import { template as template_000fbbdcf48d4125a0f43f293b8f4d01 } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
export default class PixLogo extends Component {
    get logoSrc() {
        return this.args.color === 'white' ? '/images/pix-logo-blanc.svg' : '/images/pix-logo.svg';
    }
    static{
        template_000fbbdcf48d4125a0f43f293b8f4d01(`
    <LinkTo @route="authenticated" class="pix-logo">
      <img class="pix-logo__image" src={{this.logoSrc}} alt={{t "navigation.homepage"}} />
    </LinkTo>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
