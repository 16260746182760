import { template as template_ad69db9a74014bb6a5a5292f5c29f9b4 } from "@ember/template-compiler";
export default template_ad69db9a74014bb6a5a5292f5c29f9b4(`
  <div class="campaign-step">
    <img class="campaign-step__image" role="presentation" src={{@step.image.src}} width="60" height="60" />
    <h3 class="campaign-step__title">{{@step.title}}</h3>
    <p class="campaign-step__description">{{@step.description}}</p>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
